// Vendor
// ---------------------------------

@import "vendor/normalize";
// Swiper 7.4.1
// @import "vendor/swiper";

// Global
// ---------------------------------

@import "variables";
@import "mixins";
@import "functions";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";

// Blocks
// ---------------------------------

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/btn";
@import "blocks/breadcrumbs";

// ---------------------------------

// ❗❗❗ запрещено использовать изображения в css, касается как jpg \ png, так и svg

// ❗ обязательно используйте mixin hover-focus для отключения ховеров на тач устройствах
/* @mixin hover-focus {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }

    &.focus-visible:focus {
      @content;
    }
  }
*/
/*  @include hover-focus {
      opacity: 0.8;
    }
*/
// но не используем для текстовых полей ( input, textarea )
// так же в сборке есть отдельный миксин для hover
/*@mixin hover {
    @media (hover: hover) {
      &:hover:not(.focus-visible) {
        @content;
      }
    }
  }
*/
// для focus
/*@mixin focus {
    &.focus-visible:focus {
      @content;
    }
  }
*/
// и для active
/*@mixin active {
    &.focus-visible:active {
      @content;
    }
  }
*/
// адаптив пишем внутри каждого класса (смотрим container.scss)

// для фикса проблем с vh на iOS в сборке подключен скрипт
// используя vh на проекте задавайте их также как в примере в utils.scss

// для любых transition обязательно указывайте transition-property
// transition: $trans-default ❌ ---> transition: color $trans-default ✅

* {
  font-family: "Geologica", sans-serif;
  font-weight: 400;
  font-style: normal
}
html {
  overflow-x: hidden 
}
body {
  padding-right: 0 !important;
}


[data-accordion="content"] {
  max-height: 0;
  -webkit-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
  overflow: hidden;
}
[data-tabs="element"] {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in,visibility .3s ease-in;
  -o-transition: opacity .3s ease-in,visibility .3s ease-in;
  transition: opacity .3s ease-in,visibility .3s ease-in;  
}
[data-tabs="element"].is-active {
  z-index: 1;
  visibility: visible;
  opacity: 1;
}

.phones-icon,
.phones-dd {
  fill: none;
}
.phones-icon {
  display: none;
}
.--blue-bg {
  background: #f1f5fb;
}

.page-btn {
  @include page-btn();
}

.page-h1 {
  @include page-h1();
}
.page-h2 {
  @include page-h2();
}
.page-h3 {
  @include page-h3();
}
.page-text-20 {
  @include page-text-20();
}
.page-text-16 {
  @include page-text-16();
}

.title {
  padding-top: 10px;
  padding-bottom: 120px;
 .title__wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;

  background: #f1f5fb;
  border-radius: 30px;

  padding-right: 142px;
 } 
 .title__hero {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
 }
 .title__desc {
  padding: 60px 0;
  max-width: 489px;
 }
 .title__header {
  margin-top: 0;
  margin-bottom: 30px;
 }
 .title__text {
  margin: 0;
 }
 .title__links {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 78px;
 }
 .title__link {
  &:not(:last-child) {
    margin-right: 30px;
  }
 }
}

.solutions { 
  padding-bottom: 120px;
}
  .solutions__wrapper {

  }
  .solutions__header {
    margin-top: 0;
    margin-bottom: 70px;
  }
  .solutions__items {
    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }
    }
  }
  .solutions__item-dots {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .solutions__item {
    background: #f1f5fb;
    border-radius: 20px;
    display: flex;
    justify-content: start;
    padding: 30px;
  }
  .solutions__item-img {
    max-width: 220px;
    margin-right: 30px;
    flex-shrink: 0;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .solutions__item-desc {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
  .solutions__item-tag {
    color: #2c4cbc;
    padding: 8px 12px;
    display: inline-block;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .solutions__item-name {
    margin: 0;
  }
  .solutions__item-bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .solutions__item-price {
    font-weight: 600;
  }
  .solutions__item-link {

  }
  .solutions-more {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: max-content;
  }

.advantages {
  padding: 120px 0;
  .advantages__wrapper {

  }
  .advantages__header {
    margin: 0;
    margin-bottom: 70px;
  }
  .advantages__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px 30px;
  }
  .advantages__item {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
  }
  .advantages__item-icon {
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .advantages__item-name {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .advantages__item-text {
    text-align: center;
    margin: 0;
  }
}

.clients {
  padding: 97px 0 120px;
  .clients__wrapper {

  }
  .clients__header {
    margin: 0;
    margin-bottom: 70px;
  }
  .clients__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .clients__item {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .clients__item-img {
    height: 315px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .clients__item-desc {
    padding: 30px;
    background: #f1f5fb;
    flex-grow: 10;
  }
  .clients__item-name {
    margin-bottom: 20px;
    display: block;
  }
  .clients__item-text {
    margin: 0;
  }
}

.examples {
  padding: 111px 0 120px;
  .examples__headline {
    margin-bottom: 60px;
  }
  .examples__headline-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .examples__header {
    text-align: left;
    margin: 0;
  }

  .examples__main {

  }
  .examples__slider {

  }
  .examples__slide {
    max-width: 410px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.swiper-slide-next,
    &.swiper-slide-prev,
    &.swiper-slide-active {
      .examples__slide-link,
      .examples__slide-name {
        opacity: 1;
      }
    }
  }
  .examples__slide-img {
    height: 262px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .examples__slide-name {
    min-height: 52px;
    text-align: center;
    margin-bottom: 30px;
    transition: .3s;
    opacity: 0;
  }
  .examples__slide-link {
    transition: .3s;
    opacity: 0;
  }
}

.examples-nav {
  display: flex;
  justify-content: start;
  align-items: center;
}
.examples__nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 2px solid #173DC34D;
  border-radius: 50%;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.consultation {
    padding: 100px 0;
    background: #2c4cbc;
  .consultation__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 879px;
    margin: 0 auto;
    color: #fff;
  }
  .consultation__headline {
    color: #fff;
    margin: 0 0 30px;
  }
  .consultation__text {
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center;
  }
  .consultation__link {

  }
}

.faq {
  padding: 120px 0 116px;
  .faq__wrapper {

  }
  .faq-headline {
    margin-bottom: 70px;
  }
  .faq__header {
    margin: 0;
    margin-bottom: 30px;
  }
  .faq__text {
    margin: 0;
    text-align: center;
  }
  .faq__body {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 30px;
    align-items: start;
  }

  .faq__left {

  }
  .faq__accs {

  }
  .faq__acc {
    padding: 30px 30px;
    border: 2px solid #f1f5fb;
    border-radius: 20px;
    background: #f1f5fb;
    transition: .3s;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &.is-active {
      background: transparent;
      border-color: #04113F4D;

      .faq-headline__text {
        color: #2c4cbc;
      }
      .faq-headline__icon {
        background: #f1f5fb;
        &::before {
          opacity: 0;
        }
      }
    }
  }
  .faq__acc-headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .faq-headline__text {
    font-weight: 500;
    transition: .3s;
  }
  .faq-headline__icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    position: relative;
    flex-shrink: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      transition: .3s;
    }
    &::before {
      height: 16px;
      width: 2px;
      background: #000;
    }
    &::after {
      height: 2px;
      width: 16px;
      background: #000;
      border-radius: 2px;
    }
  }
  .faq__acc-content {

  }
  .faq__acc-content__inner {
    padding-top: 20px;
    @include page-text-20();
  }

  
}
.faq__right {
  padding: 30px 30px 40px;
  border-radius: 20px;
}
.faq__right-header {
  text-align: center;
  display: block;
  margin-bottom: 30px;
}
.faq__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faq__form-field {
  padding: 10px 15px;
  display: block;
  width: 100%;
  border: 2px solid #04113F4D;
  outline: none;
  border-radius: 10px;

  @include page-text-20();

  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &.--area {

  }
}
.faq__form-submit {

}

.catalog { padding-bottom: 120px;
  .catalog__wrapper {

  }
  .catalog__header {
    margin-top: 0;
    margin-bottom: 70px;
  }
  .catalog__filters {

  }
  .catalog__filters-header {
    display: block;
    margin-bottom: 20px;
  }
  .catalog__filters-search {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
  }
  .catalog__filters-search__field {
    display: block;
    width: 100%;
    padding: 15px 20px;
    @include page-text-20();
    border: none;
    outline: none;
    background: #f1f5fb;
    border-radius: 10px;
  }
  .catalog__filters-search__btn {
    flex-shrink: 0;
    width: 104px;
    border-radius: 10px;
    margin-left: -7px;
    svg {
      fill: none;
    }
  }
  .catalog__filters-examples {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .catalog__filters-examples-items {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;
  }
  .catalog__filters-example {
    color: #2c4cbc;
    background: #f1f5fb;
    border-radius: 5px;
    padding: 8px 12px;
    margin-bottom: 5px;
    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  .catalog__body {
    margin-top: 70px;
  }
  .catalog__subheader {
    margin: 0;
    text-align: left;
    margin-bottom: 50px;
  }
  .catalog__items {

  }
  .catalog__item {
    display: flex;
    justify-content: start;
    padding: 40px;
    background: #f1f5fb;
    border-radius: 20px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .solutions__item-img {
    max-width: 410px;
    height: 262px;
    width: 100%;
    img {
      height: 100%;
    }
  }
  .solutions__item-links {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .solutions__item-link {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  .solutions__item-desc {
    width: 100%;
  }
  .catalog__more {
    display: block;
    width: max-content;
    margin: 50px auto 0;

  }
}

.detail { 
  padding-bottom: 120px;
  .detail__wrapper {

  }
  .detail__main {
    margin-top: 50px;
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 70px;
  }
  .detail__left {
    max-width: 640px;
    width: 100%;
    margin-right: 30px;
    flex-shrink: 0;
  }
  .detail__slider {
    width: 100%;
  }
  .detail__slide {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .detail__right {
    max-width: 630px;
    width: 100%;
  }
  .detail__header {
    margin: 0;
    margin-bottom: 20px;
  }
  .detail__text {
    margin: 0;
    margin-bottom: 20px;
  }
  .detail__price {
    display: block;
    margin-bottom: 50px;
  }
  .detail__links {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .detail__link {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .detail__bottom {

  }
  .detail__bottom-slider {

  }
  .detail__bottom-slide {
    max-width: 410px;
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .examples-nav {
    margin: 30px auto 0;
    width: max-content;
  }
}

.functionality {
  padding: 120px 0;
  .functionality__wrapper {

  }
  .functionality__header {
    margin: 0;
    margin-bottom: 70px;
  }
  .functionality__top {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 70px;
  }
  .functionality__top-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      margin-bottom: 20px;
    }
    span {
      text-align: center;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .functionality__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 30px;
  }
  .functionality__grid-item {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 26px;
    background: #f1f5fb;
    border-radius: 20px;

    img {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
}

.variations {
  padding: 120px 0;
  .variations__wrapper {

  }
  .variations__header {

  }
  .variations__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }
  .variations__item {
    color: #04113f;
    padding: 40px 20px;
    background: #fff;
    border-radius: 20px;
    &:nth-child(even) {
      background: #2c4cbc;
      color: #fff;
      .variations__item-name {
        color: #fff;
      }
      .variations__item-link {
        background: #fff;
        color: #04113f;
        border-color: #fff;
      }
    }
  }
  .variations__item-name {
    display: block;
    text-align: center;
    color: #2c4cbc;
  }
  .variations__item-benefits {
    margin: 30px 0;
  }
  .variations__item-benefit {
    text-align: center;
    margin: 0;
    
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .variations__item-price {
    display: block;
    margin-bottom: 30px;
  }
  .variations__item-link {
    display: block;
    width: max-content;
    margin: 0 auto;
  }
}

html body .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 0 !important;
  background: #2c4cbc;
  &:not(:last-child) {
    margin-right: 20px !important;
  }
}
.swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 1345px) {
  .title .title__hero {
    max-width: 40%;
  }
  .detail .detail__left {
    max-width: 472px;
  } 
}

@media screen and (max-width: 1080px) {
  .title .title__desc {
    max-width: 380px;
  }
  .title .title__desc {
    padding: 40px 0;
  }
  .solutions__item-img {
    max-width: 150px;
    margin-right: 20px;
  }
  .examples .examples__slide {
    max-width: auto;
  }
  .examples .examples__slide-img {
    height: 197px;
  }

  .solutions {
    padding-bottom: 80px;
  }
  .title {
    padding-bottom: 80px;
  }
  .advantages {
    padding: 80px 0;
  }
  .clients {
    padding: 80px 0;
  }
  .examples {
    padding: 80px 0;
  }
  .consultation {
    padding: 80px 0;
  }
  .faq {
    padding: 80px 0;
  }
  .functionality, .variations {
    padding: 80px 0;
  }
  .faq .faq__body {
    grid-template-columns: 2fr 1fr;
  }
  .faq .faq-headline__icon {
    width: 40px;
    height: 40px;
  }
  .catalog .solutions__item-img {
    max-width: 288px;
    height: 184px;
  }
  .catalog .catalog__filters-examples-items {
    margin-left: 10px;
  }
  .variations .variations__item {
    padding: 30px 30px;
  }
  .detail .detail__bottom-slide {
    max-width: initial;
  }
  .examples .examples__slide-link, .examples .examples__slide-name {
    opacity: 1;
  }
}
@media screen and (max-width: 1020px) {
  .phones-icon {
    display: block;
    margin-right: 5px;
  }
  .faq .faq__body {
    grid-template-columns: 1fr;
  }
  .solutions__item {
    flex-direction: column;
    align-items: center;
  }
  .solutions__item-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .solutions__item-img {
    max-width: initial;
    height: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .solutions__item-tag {
    margin-bottom: 10px;
    padding: 6px 8px;
  }
  .solutions__item-name {
    margin-bottom: 20px;
    text-align: center;
  }
  .solutions__item-bottom {
    flex-direction: column;
  }
  .solutions__item-price {
    margin-bottom: 20px;
  }
  .solutions__header,
  .advantages .advantages__header {
    margin-bottom: 20px;
  }
  .header .header__phones-headline {
    margin-bottom: 0;
  }
  .catalog .catalog__item {
    flex-direction: column;
    align-items: star;
  }
  .catalog .solutions__item-desc {
    align-items: start;
  }
  .catalog .solutions__item-bottom {
    align-items: start;
  }
  .solutions__item-text {
    margin: 0;
    margin-bottom: 23px;
  }
  .catalog .solutions__item-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px 16px;
  }
  .catalog .solutions__item-links .solutions__item-link:last-child {
    grid-column: 1/3;
    grid-row: 1/2;
  }
  .catalog .solutions__item-link:not(:first-child) {
    margin: 0;
  }
  .catalog .catalog__item {
    padding: 20px;
  }
  
}
@media screen and (max-width: 900px) {
  .title .title__hero {
    max-width: 100%;
    position: static;
    transform: none;
  }
  .title .title__wrapper {
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  .title .title__desc {
    padding: 20px 0 30px;
  }
  .title .title__header {
    text-align: center;
  }
  .title .title__text {
    text-align: center;
  }
  .title .title__links {
    margin-top: 20px;
    flex-direction: column;
  }
  .title .title__link {
    width: 100%;
    text-align: center;
  }
  .title .title__link:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;

  }
  .advantages .advantages__items {
    grid-template-columns: 1fr;
  }
  .clients .clients__items {
    grid-template-columns: 1fr;
  }
  .clients .clients__item-desc {
    padding: 20px;
  }
  .examples .examples__headline {
    margin-bottom: 20px;
  }
  .detail .detail__main {
    flex-direction: column;
    align-items: center;
  }
  .detail .detail__left {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .detail .detail__links {
    flex-direction: column;
  }
  .detail .detail__link {
    width: 100%;
  }
  .detail .detail__link:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .functionality .functionality__top {
    flex-direction: column;
  }
  .functionality .functionality__top-item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .functionality .functionality__grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .variations .variations__items {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 745px) {
  .solutions__item {
    padding: 20px;
  }
  .footer__logo, .header__logo {
    max-width: 172px;
  }
  .examples-nav {
    display: none;
  }
  .examples .examples__header {
    width: 100%;
    text-align: center;
  }
  .advantages, .clients, .consultation, .examples, .faq {
    padding: 50px 0;
  }
  .faq .faq__acc {
    padding: 20px;
  }
  .consultation .consultation__headline {
    text-align: center;
  }
  .catalog .catalog__filters-example {
    display: none;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
}

.my-modal {
  padding: 0 !important;
  background: transparent;
  max-width: 410px;
  width: 100%;
  display: none;
  overflow: visible;

  .my-modal__wrapper {
    border-radius: 20px;
    overflow: hidden;
  }
  .my-modal__inner {
    
  }
  .fancybox-close-small {
    top: -30px;
    right: -30px;
    left: unset;
    color: #fff;
  }
}
.fancybox-slide {
  background: rgb(4, 17, 63, .5);
}
