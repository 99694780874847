.breadcrumbs {
  padding: 25px 0 30px;
  .breadcrumbs__wrapper {
    
  }
  .breadcrumbs-items {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .breadcrumbs-item {
    text-decoration: none;

    display: flex;
    justify-content: start;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
      &::after {
        content: '>';
        font: inherit;
        color: inherit;
        display: block;
        margin-left: 10px;
      }
    }

    &:last-child {
      color: #727a96;
    }
  }
}
