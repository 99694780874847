@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }
}

@mixin focus {
  &.focus-visible:focus {
    @content;
  }
}

@mixin active {
  &.focus-visible:active {
    @content;
  }
}

@mixin hover-focus {
  @media (hover: hover) {
    &:hover:not(.focus-visible) {
      @content;
    }
  }

  &.focus-visible:focus {
    @content;
  }
}

// Desktop first

@mixin vp-1919 {
  @media (max-width: ($vp-1920 - 1px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: ($vp-1440 - 1px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: ($vp-1280 - 1px)) {
    @content;
  }
}

@mixin vp-1023 {
  @media (max-width: ($vp-1024 - 1px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: ($vp-768 - 1px)) {
    @content;
  }
}

@mixin vp-374 {
  @media (max-width: ($vp-375 - 1px)) {
    @content;
  }
}

// Mobile first

// @mixin vp-375 {
//   @media (min-width: $vp-375) {
//     @content;
//   }
// }

// @mixin vp-768 {
//   @media (min-width: $vp-768) {
//     @content;
//   }
// }

// @mixin vp-1024 {
//   @media (min-width: $vp-1024) {
//     @content;
//   }
// }

// @mixin vp-1280 {
//   @media (min-width: $vp-1280) {
//     @content;
//   }
// }

// @mixin vp-1440 {
//   @media (min-width: $vp-1440) {
//     @content;
//   }
// }

// @mixin vp-1920 {
//   @media (min-width: $vp-1920) {
//     @content;
//   }
// }

// ---------------------------------

// не нужно добавлять свои миксины для ресета списков, обнуления кнопок и прочие
// этим вы усложняете работу команды, проверку вашего кода и будущую поддержку проекта


@mixin page-btn {
  text-decoration: none;
  outline: none;
  padding: 10px 20px;
  display: inline-block;
  border: 2px solid #173DC34D;
  border-radius: 300px;
  background: transparent;
  text-align: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 110%;

  cursor: pointer;

  &.--big {
    padding: 16px 40px;
  }
  &.--blue {
    background: #2c4cbc;
    color: #fff;
  }
  &.--grey {
    background: #f1f5fb;
    color: #04113f;
    border: #f1f5fb;
  }
  &.--white {
    background: #fff;
    border-color: #fff;
    color: #04113f;
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    padding: 8px 15px;
    &.--big {
      padding: 14px 30px;
    }
  }
}

@mixin page-h1 {
  font-size: 54px;
  font-weight: 600;
  line-height: 100%;
  @media screen and (max-width: 1024px) {
    font-size: 42px;
  }
  @media screen and (max-width: 1020px) {
    font-size: 30px;
  }
}
@mixin page-h2 {
  font-size: 42px;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  margin-bottom: 70px;
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
  @media screen and (max-width: 1020px) {
    font-size: 20px;
  }
}
@mixin page-h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 100%;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1020px) {
    font-size: 18px;
  }
}
@mixin page-text-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 125%;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1020px) {
    font-size: 16px;
  }
}
@mixin page-text-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
}