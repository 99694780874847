.header {
    padding: 40px 0;
    position: relative;
    z-index: 10;
    .header__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header__logo {
        img {

        }
    }

    .header__nav {

    }
    .header__nav-list {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .header__nav-item {
        &:not(:last-child) {
            margin-right: 50px;
        }
        a {
            font: inherit;
            color: inherit;
        }
    }

    .header__phones {
        padding: 5px 10px;
        background: #F1F5FB;
        border-radius: 10px;
        color: #04113F;
    }
    .header__phones-headline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }
    }
    .header__phones-content {
        position: relative;
    }
    .header__phones-actual {
        padding: 3px 5px;
        border-radius: 5px;
        background: #fff;
    }
    .header__phones-list {
        background: #fff;
        border-radius: 5px;
        position: absolute;
        top: 105%;
        left: 0;
        // display: none;
    }
    .header__phones-list__inner {
        padding: 3px 5px;
    }
    .header__phones-item {
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        color: inherit;
        text-decoration: none;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .header__burger {
        width: 20px;
        height: 14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        display: none;

        &.is-active {
            &::before,
            &::after {
                bottom: unset;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            .header__burger-item {
                display: none;
            }
        }

        &::before,
        &::after {
            content: '';
            width: 100%;
            height: 2px;
            background: #04113f;
            display: block;

            position: absolute;
            left: 0;
        }
        &::before {
            top: 0;
        }
        &::after {
            bottom: 0;
        }
    }
    .header__burger-item {
        width: 100%;
        height: 2px;
        background: #04113f;
    }
}

.header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(4, 17, 62, .7);
    z-index: 9;
    display: none;
}

@media screen and (max-width: 1024px) {
    .header__logo {
        max-width: 200px;
    }
}
@media screen and (max-width: 1020px) {
    .header .header__phones-headline span {
      display: none;
    }
    .header .header__phones-list {
      position: static;
      display: block;
    }
    .header__phones-content {
      background: #fff;
    }
    .header .header__phones-content {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }
    .header__phones {
    }
    .header .header__phones {
      padding: 0;
      background: transparent;
    }
    .header__phones-actual {
      display: none;
    }
    .header .header__nav {
        margin: 0;
        position: absolute;
        left: 0;
        top: -100%;
        width: 100%;
        background: #fff;
        opacity: 0;
        z-index: -1;

        &.is-active {
            opacity: 1;
            top: 100%;
        }
    }
    .header .header__burger {
        display: flex;
        margin-left: 28px;
    }
    .header__phones {
        margin-left: auto;
    }
    .header .header__nav-list {
        margin: 0;
        padding: 50px 0;
    }
    .header .header__nav-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .header .header__nav-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 40px;
    }
    .header .header__phones-list__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
    }
    .header {
        background: #fff;
    }
    .header .header__phones-item {
        font-size: 16px;
    }
    .header .header__phones-item:not(:last-child) {
        margin-bottom: 40px;
    }
}