.footer {
    background: #04113f;
    padding: 50px 0 56px;
    .footer__wrapper {

    }
    .footer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 40px;
        border-bottom: 2px solid rgb(255, 255, 255, .2);
        margin-bottom: 40px;
    }
    .footer__logo {
        img {

        }
    }
    .footer__nav {

    }
    .footer__nav-list {
        list-style-type: none;
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 0;
    }
    .footer__nav-item {
        color: #fff;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        
        &:not(:last-child) {
            margin-right: 50px;
        }
        a {
            color: inherit;
            font: inherit;
        }
    }
    .footer__phones {
        padding: 5px 10px;
        background: rgb(255, 255, 255, .2);
        border-radius: 10px;
        color: #fff;
    }
    .footer__phones-headline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;        
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }
    }
    .footer__phones-content {
        position: relative;
    }
    .footer__phones-actual {
        padding: 3px 5px;
        border-radius: 5px;
        background: #04113F;
    }
    .footer__phones-list {
        background: #fff;
        padding: 3px 5px;
        border-radius: 5px;
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
    }
    .footer__phones-item {
        display: block;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        color: inherit;
        text-decoration: none;
    }
    .footer__text-under {
        width: 100%;
        margin-top: 30px;
        span {
            display: block;
            max-width: 389px;
            width: 100%;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
            color: #fff;
            opacity: .5;
        }
    }

    .footer__bot {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer__copy {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;        
    }
    .footer__docs {
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .footer__docs-item {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: underline;    
        color: rgb(255, 255, 255, .5);    

        &:not(:last-child) {
            margin-right: 40px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .footer__logo {
        max-width: 200px;
    }
    .footer .footer__nav-item{
        font-size: 16px;
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
    .footer .footer__text-under span {
        font-size: 16px;
    }
    .footer .footer__copy {
        display: none;
    }
    .footer .footer__bot {
        justify-content: end;
    }
    .footer .footer__docs-item {
        font-size: 14px;
    }
}
@media screen and (max-width: 1020px) {
    .footer .footer__text-under {
        width: auto;
        order: 2;
        margin-top: 0px;
        text-align: center;
        margin-bottom: 30px;
    }
    .footer .footer__nav-list {
        display: none;
    }
    .footer .footer-top {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }
    .footer__logo {
        margin-bottom: 20px;
        order: 1;
    }
    .footer .footer__phones {
        order: 3;
        padding: 0;
        background: transparent;
    }
    .footer .footer__phones-headline {
        display: none;
    }
    .footer .footer__bot {
        flex-direction: column-reverse;

    }
    .footer .footer__docs {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .footer .footer__docs-item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .footer .footer__copy {
        display: block;
        text-align: center;
    }
}